import React from 'react'
import { Link } from 'gatsby'
import SplitText from './SplitText'
import Picture from './Picture'

const CardProject = ({ project }) => {

	const imageSrc = project.image.fluid ? project.image.fluid.src : project.image.url
	const imageSrcSet = project.image.fluid ? project.image.fluid.srcSet : ''

	let title = null
	if (project.title[0]) title = project.title[0].text
	else if (project.title) title = project.title.text

	let location = null
	if (project.location[0]) location = project.location[0].text
	else if (project.location) location = project.location.text

	return (
		<article
			className="CardProject"
		>
			<div className="CardProject__ImageContainer">
				<Picture
					className="CardProject__Image"
					src={imageSrc}
					srcSet={imageSrcSet}
					alt={project.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 30vw"
					linksTo={`/${project.uid}`}
					data-is-on-screen
				/>
			</div>
			<h6 className="CardProject__Title">
				<SplitText splitBy="chars">
					{title}
				</SplitText>
			</h6>
			<div className="CardProject__Location">
				<SplitText splitBy="chars">
					{location}
				</SplitText>
			</div>
		</article>
	)
}

export default CardProject;