const smoothScrollTo = (targetEl, offset = 0) => {
	const targetElDistanceFromTop = window.pageYOffset + targetEl.getBoundingClientRect().top;
	const target = targetElDistanceFromTop + offset;

	// ie and edge fix
	if (window.document.documentMode || /Edge/.test(navigator.userAgent)) {
		window.scroll(0, target);
		return;
	}

	window.scroll({
		top: target,
		left: 0,
		behavior: 'smooth'
	});
}

export default smoothScrollTo;